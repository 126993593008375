<template>
  <div class="email-rightbar mb-3">
    <div v-if="conversation" class="card">
      <div class="card-body">
        <h3>
          <i v-if="conversation.closed" class="mdi mdi-check-bold"></i>
          {{ conversation.subject }}
        </h3>
        <hr />
        <p>
          {{ conversation.body }}
        </p>
        <small class="text-muted">
          Enviado {{ conversation.created_at | moment("D MMMM [de] YYYY") }}
        </small>
      </div>
    </div>

    <b-button
      v-if="missingOldItems > 0"
      block
      variant="secondary"
      size="lg"
      @click="getMessages({ beforeId })"
    >
      Ver mais mensagens antigas
    </b-button>

    <div v-if="conversation" class="card">
      <div class="card-body">
        <div v-for="message in messages" :key="message.id" class="mb-4">
          <div v-if="message.user_id === currentUser.id" class="float-right">
            <i
              class="mdi mdi-trash-can"
              style="cursor: pointer;"
              @click="confirmDeleteMessage(message)"
            ></i>
          </div>
          <div class="media mb-3">
            <img
              class="d-flex mr-3 rounded-circle avatar-sm"
              :src="
                message.author.avatar
                  ? $getImageUrl(message.author.avatar.originalName)
                  : $defaultUserAvatar
              "
            />
            <div class="media-body">
              <h4 class="font-size-15 m-0">{{ message.author | name }}</h4>
              <small class="text-muted">{{ message.author.username }}</small>
            </div>
          </div>

          <div v-html="message.body.replace(/\n/g, '<br>')"></div>

          <small class="text-muted">
            Enviado
            {{ new Date(message.created_at) | moment("D MMMM [de] YYYY") }}
          </small>

          <hr />
        </div>

        <div v-if="!conversation.closed" class="mt-4">
          <form @submit.prevent="onReply">
            <b-form-textarea
              rows="4"
              max-rows="10"
              class="form-control"
              placeholder="Escreva aqui sua mensagem..."
              v-model="reply"
            ></b-form-textarea>

            <button class="btn btn-success mt-3">
              <i class="mdi mdi-reply"></i> Enviar
            </button>
            <button
              @click.prevent="setClosed(true)"
              class="float-right btn btn-info mt-3"
            >
              <i class="mdi mdi-check-bold"></i> Dúvida solucionada
            </button>
          </form>
        </div>

        <div v-else class="mt-4">
          <form @submit.prevent="onReply">
            <button
              @click.prevent="setClosed(false)"
              class="float-right btn btn-warning mt-3"
            >
              <i class="mdi mdi-lock-open"></i> Reabrir
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { mapState } from "vuex";

export default {
  data() {
    return {
      conversation: null,
      messages: [],
      missingOldItems: 0,
      reply: "",
      timeout: null,
    };
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    afterId() {
      if (!this.messages.length) {
        return;
      }

      return Math.max(...this.messages.map((m) => m.id));
    },

    beforeId() {
      if (!this.messages.length) {
        return;
      }

      return Math.min(...this.messages.map((m) => m.id));
    },
  },

  async created() {
    this.start();
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    async start() {
      clearTimeout(this.timeout);
      await this.loadConversation();

      const loadNewMessages = () => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.getMessages(
            {
              afterId: this.afterId,
            },
            true
          ).finally(loadNewMessages);
        }, 3000);
      };

      await this.getMessages({ page: 1 });
      loadNewMessages();
    },

    loadConversation() {
      return api
        .getConversationById(this.$route.params.id)
        .then((conversation) => {
          this.conversation = conversation;
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar a conversa!!", "error");
        });
    },

    getMessages(params = {}, silentFail = false) {
      return api
        .getConversationMessages(this.$route.params.id, params)
        .then((messages) => {
          if (params.page || params.beforeId) {
            this.missingOldItems = +messages.total - messages.items.length;
          }

          this.messages = this.messages
            .concat(
              messages.items.filter((msg) =>
                this.messages.every((other) => other.id !== msg.id)
              )
            )
            .sort((a, b) => a.id - b.id);
        })
        .catch(() => {
          if (silentFail) {
            return;
          }
          this.$swal("Opa", "Falha ao carregar a mensagens!!", "error");
        });
    },

    onReply() {
      if (!this.reply) {
        return this.$swal(
          "Opa",
          "Por favor preencha todos os campos!",
          "warning"
        );
      }

      api
        .replyToConversation(this.conversation.id, { body: this.reply })
        .then((message) => {
          this.reply = "";
          this.messages = this.messages.concat(message);
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao responder!", "error");
        });
    },

    confirmDeleteMessage(message) {
      this.$swal({
        title: "Você tem certeza?",
        text: "Esta ação não pode ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#02a499",
        cancelButtonColor: "#ec4561",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, deletar!",
      }).then((result) => {
        if (result.value) {
          return this.doDeleteMessage(message);
        }
      });
    },

    doDeleteMessage(message) {
      api
        .deleteMessage(this.conversation.id, message.id)
        .then(() => {
          this.messages = this.messages.filter((m) => m.id !== message.id);
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao apagar mensagem!", "error");
        });
    },

    setClosed(closed) {
      api
        .updateConversation(this.conversation.id, { closed })
        .then((conversation) => {
          this.conversation = {
            ...this.conversation,
            closed: conversation.closed,
          };
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao atualizar estado!", "error");
        });
    },
  },

  watch: {
    "$route.params.id"() {
      this.start();
    },
  },
};
</script>
